import Cookies from 'js-cookie';

export class MsDebug {

    constructor()
    {
        this.template = `<div id="__debuginfo">
	<div id="__debuginfo_main" class="__debuginfo-icon __debuginfo-button" data-action="main">🐞</div>
	<div class="__debuginfo-buttons">
		<div id="__debuginfo_cols" class="__debuginfo-button" data-action="cols">cols</div>
		<div id="__debuginfo_rows" class="__debuginfo-button" data-action="rows">rows</div>
		<div id="__debuginfo_flex" class="__debuginfo-button" data-action="flex">flex</div>
		<div id="__debuginfo_cont" class="__debuginfo-button" data-action="cont">cont</div>
		<div id="__debuginfo_grid" class="__debuginfo-button" data-action="grid">grid</div>
		<div id="__debuginfo_form" class="__debuginfo-button" data-action="form">form</div>
	</div>
	<div class="gridinfo">
		<div id="__debuginfo_vw" class="vw"></div>
	</div>
</div>
		`;

        this.init();
    }

    init()
    {
        if(document.body.classList.contains('pdebugmode1')) {
            document.body.insertAdjacentHTML('beforeend', this.template);
            /** @protected {HTMLElement} */
            this.debugPanel = document.getElementById('__debuginfo');
            this.vw = document.getElementById('__debuginfo_vw');
            this.debugPanel.addEventListener('click', this.onClick.bind(this));

            window.onresize = this.onResize.bind(this);
            this.handleResize(window.innerWidth);

            let buttons = this.debugPanel.getElementsByClassName('__debuginfo-button');
            for(let button of buttons) {
                let cvalue = Cookies.get('__debuginfo-button-' + button.dataset.action) === "true";
                if(null !== cvalue) {
                    this.toggleButton(button, cvalue);
                }
            }
        }
    }

    onClick(event)
    {
        if(event.target.classList.contains('__debuginfo-button')) {
            this.toggleButton(event.target);
        }
    }

    onResize(event)
    {
        this.handleResize(event.target.innerWidth);
    }

    handleResize(w)
    {
        this.vw.innerText = w;
    }

    /**
     * @param {HTMLElement} button
     * @param {boolean} [forceState]
     */
    toggleButton(button, forceState)
    {
        let btstate = undefined;
        if(undefined === forceState) {
            btstate = document.body.classList.toggle('__debuginfo-' + button.dataset.action);
        } else if(true === forceState) {
            document.body.classList.add('__debuginfo-' + button.dataset.action);
            btstate = true;
        } else if(false === forceState) {
            document.body.classList.remove('__debuginfo-' + button.dataset.action);
            btstate = false;
        }
        if(btstate) {
        } else {
        }
        Cookies.set('__debuginfo-button-' + button.dataset.action, btstate, false, '/', null, null, 'Strict');
    }
}
