export class MsApp {

    constructor()
    {
        this.pageY = 0;

        window.addEventListener('resize', this.onResize.bind(this));
        window.addEventListener('scroll', this.onScroll.bind(this));

        this.handleScroll();
    }

    onResize(event)
    {
    }

    onScroll(event)
    {
        this.handleScroll();
    }

    handleScroll()
    {
        let pageYNew = window.pageYOffset;
        if(pageYNew < 0) {
            // wegen iPad (negative Position)
            pageYNew = 0;
        }

        if(0 === pageYNew) {
            document.body.classList.add('scrollpos-top');
            document.body.classList.remove('scrollpos-nottop');
        } else {
            document.body.classList.remove('scrollpos-top');
            document.body.classList.add('scrollpos-nottop');
        }

        this.pageY = pageYNew;
    }
}
